<!-- Footer Section start -->
<div class="copyright-section index-footer">
  <p>{{today | date:'y'}} copyright by iBobo team</p>
</div>
<!-- Footer Section End -->
<!-- Tap on Top -->
<div [ngClass]="{'tap-top': showScroll}" (click)="onActivate()">
  <div>
    <i class="fa fa-angle-double-up"></i>
  </div>
</div>
<!-- Tap on Ends -->